import { Injectable } from '@angular/core';
import { dockerEnvironment } from '../../environments/environment';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthenticateUserCommand, CommandUserData } from '../../core/models/commads';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PessoaService {

  private apiBaseUrl = dockerEnvironment.apiUrl

  constructor(private http: HttpClient) { }

  create(user: CommandUserData ) {
    return this.http.post<any>(`${this.apiBaseUrl}/v1/auth/user/register`, user)
  }

  requestPrize(body: any){
    return this.http.post<any>(`${this.apiBaseUrl}/v1/user/request-prize`, body)
  }

  signIn(user: AuthenticateUserCommand){
    return this.http.post<any>(`${this.apiBaseUrl}/v1/auth/user/authenticate`, user)
 }

  nominatedInstitution(body: any){
    return this.http.post(`${this.apiBaseUrl}/v1/user/nominated-institution`, body)
  }

  userScore(page: number, perPage: number){
    return this.http.get(`${this.apiBaseUrl}/v1/user/current-score?page=${page}&perPage=${perPage}`)
  }

  getUser(id: any){
    return this.http.get<any>(`${this.apiBaseUrl}/v1/user/${id}`)
  }

  delete(id: any){
    return this.http.delete<any>(`${this.apiBaseUrl}/v1/user/${id}/delete`)
  }

  update(id: any,user: any){
    return this.http.put<any>(`${this.apiBaseUrl}/v1/user/${id}`, user)
  }

  getFiscalDocuments(page: number = 0, perPage: number = 10, documentNumber?: number, fiscalDocumentType?: string, documentRegion?: string, userId?: string, region?: string, competence?: string, allDocuments?: boolean) {
    let url = `${this.apiBaseUrl}/v1/user/fiscal-documents?page=${page}&per_page=${perPage}`;
    console.log(allDocuments)

    if (allDocuments){
      url += `&allDocuments=${allDocuments}`;
    }

    if (!allDocuments){
      url += `&allDocuments=false`;
    }
    if (documentNumber) {
      url += `&documentNumber=${documentNumber}`;
    }

    if (fiscalDocumentType) {
      url += `&fiscalDocumentTyoe=${fiscalDocumentType}`;
    }

    if (documentRegion) {
      url += `&documentRegion=${documentRegion}`;
    }

    if (userId) {
      url += `&userId=${userId}`;
    }

    if (competence) {
      url += `&competence=${competence}`;
    }

    if (region) {
      url += `&region=${region}`;
    }

    // Realize a chamada HTTP
    return this.http.get<any>(url);
  }

  getMyPrizes(page: number = 0, perPage: number = 10, raffle?: string, startDate?: Date, endDate?: Date, name?: string){
    let url = `${this.apiBaseUrl}/v1/user/my-prizes?page=${page}&per_page=${perPage}`;

    if (raffle) {
      url += `&raffle=${raffle}`;
    }

    if (startDate) {
      url += `&startDate=${startDate.toISOString()}`;
    }

    if (endDate) {
      url += `&endDate=${endDate.toISOString()}`;
    }

    return this.http.get<any>(url);

  }

  getInstitution(id: any){
    return this.http.get<any>(`${this.apiBaseUrl}/v1/institution/${id}`)
  }

  getMyPrizeById(id: any){
    return this.http.get<any>(`${this.apiBaseUrl}/v1/user/my-prizes/${id}`)
  }

  aprovarRegistro(id: string){
    return this.http.get<any>(this.apiBaseUrl + `/v1/user/approved-user/${id}`)
  }

  getMyMessages(page: number = 0, perPage: number = 10, userId: string): Observable<any>{
    let url = `${this.apiBaseUrl}/v1/user/my-messages?userId=${userId}&page=${page}&per_page=${perPage}`;

/*     if (userId) {
      url += `&userId=${userId}`;
    } */

    return this.http.get<any>(url);
  }

  relatorioNotas(id: any){
    return this.http.get<any>(this.apiBaseUrl + `/v1/user/report/fiscal-documents?userId=${id}`)
  }

  getMyTickets(page?: any, perPage?: any, userId?: string, numeroBilhete?: string, regiaoBilhete?: string,  periodoInicial?: string, periodoFinal?: string,): Observable<any>{
  
    let url = `${this.apiBaseUrl}/v1/user/bilhetes?userId=${userId}`;

     
     
    if (perPage) {
       
      url += `&perPage=${perPage}`;
    }

    if (page) {
      url += `&page=${page}`;
    }


    if (regiaoBilhete) {
      url += `&regiaoBilhete=${regiaoBilhete}`;
    }


    if(numeroBilhete) {
      url += `&numeroBilhete=${numeroBilhete}`;
    }

    if (periodoInicial) {
      url += `&competence=${periodoInicial}`;
    }

    if (periodoFinal) {
      url += `&periodoFinal=${periodoFinal}`;
    } 
     
    return this.http.get<any>(url);

  }

  getRequestMyPrize(id: any){
    return this.http.get<any>(this.apiBaseUrl + `/v1/user/request-prize/${id}`)
  }

  postRequestMyPrize(id: any, files: any){
    const body = {
      prizeId: id,
      files: files
    }
    return this.http.post<any>(this.apiBaseUrl + `/v1/user/request-prize`, body)
  }
}
